
import Campus from "../assets/Campus.jpg";
import YouTubeThumbnailPlayer from "./YouTubeThumbnailPlayer";
import Uni1 from "../assets/campus1.jpg";
import Uni2 from "../assets/campus2.jpg";
import Uni3 from "../assets/campus3.webp";
import Uni4 from "../assets/institute (2).jpg";
import Uni5 from "../assets/campus4.webp";
import Uni6 from "../assets/campus5.jpg";
import Uni7 from "../assets/Uni7.jpg";
import Uni8 from "../assets/Uni8.jpg";
import { IonIcon } from '@ionic/react';
import { cloudDownload, documentText } from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComputerMouse } from '@fortawesome/free-solid-svg-icons';

const CourseCard = ({ image, university, course, description }) => (
  <div className="bg-white rounded-[25px] shadow-xl overflow-hidden mx-3 mb-6 sm:mb-8 lg:mb-10 w-full sm:max-w-sm md:max-w-md lg:max-w-lg transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl">
    <div className="relative">
      <img
        src={image}
        alt={university}
        className="w-full h-48 object-cover sm:h-56 md:h-64 lg:h-72"
      />
      <div className="py-3 px-5">
        <h2 className="text-red-500 font-semibold text-lg sm:text-xl text-center">
          {university}
        </h2>
        <h3 className="text-xl sm:text-2xl font-semibold py-2 sm:py-3">
          {course}
        </h3>
    <p className="text-gray-600 text-sm sm:text-base mb-4 text-justify">{description}</p>
        <div className="flex justify-between items-center mt-4">
          <button className="bg-red-500 text-white px-6 py-2 rounded-full text-sm sm:text-base">
            Apply
          </button>
          <div className="flex items-center text-xs sm:text-sm text-gray-800">
            <div className="flex flex-col px-3 sm:px-6">
              <span className="font-semibold text-base sm:text-lg">2 Year</span>
              <span className="text-gray-500">Course</span>
            </div>
            <div className="h-10 w-px bg-gray-500 mx-1 sm:mx-3"></div>
            <div className="flex flex-col px-3 sm:px-6">
              <span className="font-semibold text-base sm:text-lg">
                Masters Degree
              </span>
              <span className="text-gray-500">Degree</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Home = () => {
  return (
    <div className="font-outfit">
      <div className="relative">
        <img
          src={Uni4}
          alt="University Building"
          className="w-full max-h-80 object-cover md:max-h-96 lg:max-h-[600px]"
        />
        <div className="absolute right-0 bottom-1/4 flex flex-col items-start text-right p-8">
          <h4 className="text-2xl font-bold text-white mb-2">
            EXPLORE THE WORLD OF
          </h4>
          <h2 className="text-3xl font-extrabold text-red-600">OUR GRADUATES</h2>
        </div>
      </div>

      <div className="relative mt-[-28px] flex flex-wrap justify-center bg-transparent px-4 sm:px-0">
  <div className="bg-red-500 font-bold bg-opacity-65 text-white py-5 sm:py-7 shadow-lg text-center relative w-full sm:w-[260px] mb-4 sm:mb-0 mx-2 rounded-lg focus-within:ring-4 focus-within:ring-red-300 transition-all duration-300 ease-in-out">
    <div className="font-bold text-lg sm:text-xl tracking-[0.15rem] uppercase">
      APPLY ONLINE
    </div>
    <div className="mt-4 sm:mt-6">
      <FontAwesomeIcon icon={faComputerMouse} className="w-8 h-7 sm:w-10 sm:h-9" />
    </div>
    <button className="mt-4 sm:mt-0 flex items-center justify-center bg-white text-red-500 rounded-full w-8 h-8 sm:w-10 sm:h-10 shadow-md absolute bottom-[-16px] left-1/2 transform -translate-x-1/2 focus:outline-none focus:ring-2 focus:ring-red-300">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="2 2 18 20"
        stroke="currentColor"
        className="w-5 h-4 sm:w-6 sm:h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 12h14m-7-7l7 7-7 7"
        />
      </svg>
    </button>
  </div>
  
  <div className="bg-blue-600 bg-opacity-65 text-white font-bold py-5 sm:py-7 shadow-lg text-center relative w-full sm:w-[260px] mb-4 sm:mb-0 mx-2 rounded-lg focus-within:ring-4 focus-within:ring-blue-300 transition-all duration-300 ease-in-out">
    <div className="font-bold text-lg sm:text-xl tracking-[0.20rem] uppercase">
      DOWNLOAD
      <br />
      PROSPECTS
    </div>
    <div className="mt-4 sm:mt-6">
      <IonIcon icon={cloudDownload} className="w-8 h-7 sm:w-10 sm:h-9" />
    </div>
    <button className="mt-4 sm:mt-0 flex items-center justify-center bg-white text-blue-600 rounded-full w-8 h-8 sm:w-10 sm:h-10 shadow-md absolute bottom-[-16px] left-1/2 transform -translate-x-1/2 focus:outline-none focus:ring-2 focus:ring-blue-300">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="2 2 18 20"
        stroke="currentColor"
        className="w-5 h-4 sm:w-6 sm:h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 12h14m-7-7l7 7-7 7"
        />
      </svg>
    </button>
  </div>
  
  <div className="bg-red-500 font-bold bg-opacity-65 text-white py-5 sm:py-7 shadow-lg text-center relative w-full sm:w-[260px] mx-2 rounded-lg focus-within:ring-4 focus-within:ring-red-300 transition-all duration-300 ease-in-out">
    <div className="font-bold text-lg sm:text-xl tracking-[0.15rem] uppercase">CERTIFICATION</div>
    <div className="mt-4 sm:mt-6">
      <IonIcon icon={documentText} className="w-8 h-7 sm:w-10 sm:h-9" />
    </div>
    <button className="mt-4 sm:mt-0 flex items-center justify-center bg-white text-red-500 rounded-full w-8 h-8 sm:w-10 sm:h-10 shadow-md absolute bottom-[-16px] left-1/2 transform -translate-x-1/2 focus:outline-none focus:ring-2 focus:ring-red-300">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="2 2 18 20"
        stroke="currentColor"
        className="w-5 h-4 sm:w-6 sm:h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 12h14m-7-7l7 7-7 7"
        />
      </svg>
    </button>
  </div>
</div>

      <div className="flex flex-col md:flex-row justify-between items-start px-4 py-[50px]">
        <div className="md:w-1/2 lg:w-7/12 mt-5">
          <YouTubeThumbnailPlayer
            videoId="0j78_6JtARU"
            className="max-w-xl mx-auto"
          />
        </div>

        <div className="md:w-1/2 lg:w-5/12 md:ml-8 mt-4 md:mt-0">
          <section className="p-4">
            <h3 className="text-2xl font-bold mb-4">ABOUT US</h3>
            <h4 className="text-3xl font-bold mb-2">Welcome to</h4>
            <h1 className="text-red-600 text-4xl mb-2">
              <strong>upGrad</strong>
            </h1>
            <p className="mt-2 mb-6 text-justify font-poppins text-sm tracking-wide">
      upGrad is the overseas education division of Asia's leading integrated learning, skilling, and workforce development company. Their mission is to transform your aspiration of studying abroad into a tangible experience. upGrad offers distinctive pathway programs designed to provide easy, flexible, and affordable access to international education, ensuring students can achieve their academic and professional goals abroad.
    </p>
            <button className="bg-red-600 text-white font-bold py-2 px-5 rounded-full hover:bg-red-700 transition duration-300">
              Read More
            </button>
          </section>
        </div>
      </div>

      <div className="mt-8 px-4 py-12 bg-[#f6efea]">
        <h1 className="text-2xl font-semibold mb-6 px-9">FEATURED COURSES</h1>
        <p className="text-3xl font-semibold mb-8 px-9">
          We're here to <span className="text-red-500">help</span> you <br />
          build your career
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 ml-6 mr-12">
          <CourseCard
            image={Campus}
            university="Liverpool John Moores University"
            course="Master of Business Administration"
            description="With a focus on quality education, upGrad's MBA programs provide industry-relevant curriculum, experienced faculty, and a global learning experience to help students achieve their professional aspirations."
          />
          <CourseCard
            image={Uni1}
            university="Deakin Business School"
            course="Master of Business Administration"
            description="With a focus on quality education, upGrad's MBA programs provide industry-relevant curriculum, experienced faculty, and a global learning experience to help students achieve their professional aspirations."
          />
          <CourseCard
            image={Uni2}
            university="Colorado State University"
            course="Master of Business Administration"
            description="upGrad partners with Colorado State University to offer flexible and affordable MBA programs, providing industry-relevant curriculum and a global learning experience to help students achieve their professional goals."
          />
          <CourseCard
            image={Uni3}
            university="Colorado State University"
            course="Master of Business Administration"
            description="upGrad partners with Colorado State University to offer flexible and affordable MBA programs, providing industry-relevant curriculum and a global learning experience to help students achieve their professional goals."
          />
          <CourseCard
            image={Uni4}
            university="Colorado State University"
            course="Master of Business Administration"
            description="upGrad partners with Colorado State University to offer flexible and affordable MBA programs, providing industry-relevant curriculum and a global learning experience to help students achieve their professional goals."
          />
          <CourseCard
            image={Uni5}
            university="Colorado State University"
            course="Master of Business Administration"
            description="upGrad partners with Colorado State University to offer flexible and affordable MBA programs, providing industry-relevant curriculum and a global learning experience to help students achieve their professional goals."
          />
          <CourseCard
            image={Uni6}
            university="Colorado State University"
            course="Master of Business Administration"
            description="upGrad partners with Colorado State University to offer flexible and affordable MBA programs, providing industry-relevant curriculum and a global learning experience to help students achieve their professional goals."
          />
          <CourseCard
            image={Uni7}
            university="Colorado State University"
            course="Master of Business Administration"
            description="upGrad partners with Colorado State University to offer flexible and affordable MBA programs, providing industry-relevant curriculum and a global learning experience to help students achieve their professional goals."
          />
          <CourseCard
            image={Uni8}
            university="Colorado State University"
            course="Master of Business Administration"
            description="upGrad partners with Colorado State University to offer flexible and affordable MBA programs, providing industry-relevant curriculum and a global learning experience to help students achieve their professional goals."
          />
        </div>
      </div>

      <div className="min-h-screen flex flex-col">
        <div className="flex flex-col h-screen relative">
          <div className="bg-black text-white h-[55%] flex flex-col items-center p-4">
            <div className="mt-[70px] w-full">
            <h1 className=" text-white text-2xl md:text-3xl tracking-tighter text-center mb-1 font-risque">
                YOUR BRIGHT FUTURE
              </h1>
              <h2 className="text-red-600 text-xl md:text-2xl tracking-tighter text-center font-risque">
                IS OUR MISSION
              </h2>
            </div>
          </div>

          <div className="bg-white h-[35%] flex items-center justify-center p-4"></div>
          <div className="bg-red-600 h-[10%] flex items-center justify-center p-4"></div>
          <div className="absolute left-1/2 top-[55%] transform -translate-x-1/2 -translate-y-1/2 w-full max-w-xl border-4 border-white rounded-lg overflow-hidden">
            <YouTubeThumbnailPlayer videoId="GT0-lVWsmkg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;