import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { motion } from 'framer-motion';
import "./App.css";
import Homee from "./components/Homee.jsx";
import Courses from "./components/CoursePage.jsx";
import AboutUs from "./components/AboutUs.jsx";
import Navbar from "./components/Navbar.jsx";
import Footer from "./components/Footer.jsx";
import ContactUs from "./components/ContactUs.jsx";
import LoadingScreen from "./components/Loadingscreen.jsx";

const AnimatedUpgradLogo = () => {
  const letters = ['u', 'p', 'G', 'r', 'a', 'd'];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const letterVariants = {
    hidden: {
      opacity: 0,
      y: -20,
      x: Math.random() * 40 - 20, // Random x position for scattering effect
      rotate: Math.random() * 360, // Random rotation for scattering effect
    },
    visible: {
      opacity: 1,
      y: 0,
      x: 0,
      rotate: 0,
      transition: {
        type: 'spring',
        damping: 10,
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      className="flex items-center justify-center w-40 h-auto py-5"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {letters.map((letter, index) => (
        <motion.span
          key={index}
          className="text-4xl font-bold"
          variants={letterVariants}
          style={{ color: '#ff5c35' }}
        >
          {letter}
        </motion.span>
      ))}
    </motion.div>
  );
};
function App() {
  return (
    <Router>
      <LoadingScreen>
        <div>
          <div className="bg-background text-foreground" />
          <header className="bg-white p-4 text-red-600 ml-8">
            <AnimatedUpgradLogo />
          </header>
          <Navbar />
          <Routes>
            <Route path="/" element={<Homee />} />
            <Route path="courses" element={<Courses />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="about" element={<AboutUs />} />
          </Routes>
          <Footer />
        </div>
      </LoadingScreen>
    </Router>
  );
}

export default App;