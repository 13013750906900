import React, { useState } from 'react';
import handleSubmit from './FormSubmitHandler'; // Import the handleSubmit function as 
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer for notifications


const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [errors, setErrors] = useState({});


  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) newErrors.phone = 'Phone number must be 10 digits';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await handleSubmit(event, formData, setErrors);
      console.log('Response from server:', response);

      if (response.status === 'verification_sent') {
        toast.info('A verification email has been sent to your email address. Please check your inbox to confirm your email address.');
      } else if (response.status === 'email_sent') {
        toast.success('Thanks for contacting us, we will be in touch with you soon.');
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
        setErrors({});
      } else if (response.status === 'error') {
        toast.error(`An error occurred: ${response.message}`);
      } else {
        toast.error('Unexpected response received. Please try again later.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      toast.error('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <div className="bg-white ">
     
   
      <div className="bg-black text-white py-10 px-5 md:px-20 lg:px-40 text-start">
        <div className="text-3xl md:text-5xl font-bold">
          <span className="text-red-600">Contact</span> Us
        </div>
        <div className="text-xl md:text-3xl mt-5">
          <span className="text-red-600">upGrad</span> Kollam is ready for you
        </div>
      </div>

      <div className="bg-red-600  min-h-64"></div>

      <main className="p-8 ">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row gap-10  -translate-y-48">
          <div className="md:w-1/3 bg-white p-6 h-auto md:h-80 shadow-md shadow-slate-300">
            <h2 className="text-xl font-bold mb-4">Contact info</h2>
            <p className="mb-6">Address: Kollam</p>
            <div className="flex items-center mb-6">
              <svg className="w-6 h-6 mr-4 md:mr-9 text-red-500 mr-2 fill-red-600" fill="none" stroke="red-600" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
              </svg>
              <p>+91 1234567890</p>
            </div>
            <div className="flex items-center mb-6">
              <svg className="w-6 h-6  mr-4 md:mr-9 text-red-500 mr-2 fill-red-600" fill="none" stroke="red-600" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
              </svg>
              <p>+91 1234567890</p>
            </div>
            <div className="flex items-center">
              <svg className="w-6 h-6  mr-4 md:mr-9 text-white mr-2 fill-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
              </svg>
              <p>www.upgrad.com</p>
            </div>
          </div>
          <div className="w-full md:w-2/3">
            <iframe
              src="https://www.openstreetmap.org/export/embed.html?bbox=76.6091,8.8882,76.6191,8.8982&layer=mapnik&marker=8.8932,76.6141"
              style={{ border: 0, width: '100%', height: '320px' }}
              allowFullScreen=""
              loading="lazy"
              title="map">
            </iframe>
          </div>
        </div>

        <div className="max-w-5xl mx-auto px-4 py-8">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-9">
            We're here to <span className="text-red-500">help</span> you
          </h1>
          <p className="text-start mb-8 ">
            Welcome to the UpGrad Kollam ! Our dedicated team is here to provide you with the support you need. Whether you have questions
            about our courses, need help with your enrollment process, or require technical assistance, we are committed to ensuring you have a
            smooth and enriching experience with us. Reach out to us anytime, and we'll be happy to assist you!
          </p>
        </div>
        inventory managemeny price deails 
        employees
        employees cutomize calls 

        sales 

        <div className="bg-white py-8 ">
          <div className=" grid grid-cols-1 md:grid-cols-3 items-center justify-start  md:items-start">
            <div className="flex items-start mb-8 md:mb-0 md:mr-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8  fill-red-600 text-red-600 mr-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <div>
                <h3 className="font-bold">Phone</h3>
                <p>+91 1234567890</p>
                <p>+91 1234567890</p>
              </div>
            </div>
            <div className="flex items-start mb-8 md:mb-0 md:mr-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 fill-red-600 text-red-600 mr-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 2C8.13401 2 5 5.13401 5 9C5 12.866 12 22 12 22C12 22 19 12.866 19 9C19 5.13401 15.866 2 12 2ZM12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11Z"
                />
              </svg>
              <div>
                <h3 className="font-bold">Address</h3>
                <p>Kollam</p>
              </div>
            </div>
            <div className="flex items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 fill-red-600 text-white mr-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <div>
                <h3 className="font-bold">E-mail</h3>
                <p>www.upgrad.com</p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-5xl mx-auto px-4 py-8">
          <h3 className="text-3xl md:text-4xl font-bold text-center mb-9 ">Get in touch with us</h3>
          <p>We'd love to hear from you! Whether you have questions about our programs, need support, or just want to learn more about UpGrad Kollam, feel free to reach out to us.Stay connected and updated by following us on Facebook, Twitter, LinkedIn, and Instagram. You can also send us a message through our contact form and we’ll get back to you as soon as possible. We look forward to connecting with you!</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center justify-start ">
          <div className="bg-white p-6 max-w-lg w-full">
            <form className="space-y-6" onSubmit={onSubmit}>
              <div className="relative">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-red-600 focus:outline-none focus:ring-0"
                  placeholder="Enter your name"
                />
                <label
                  htmlFor="name"
                  className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75"
                >
                  Enter your name
                </label>
                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
              </div>
            </form>
          </div>
          <div className="bg-white  p-6 max-w-lg w-full">
            <form className="space-y-6" onSubmit={onSubmit}>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-red-600 focus:outline-none focus:ring-0"
                  placeholder="Your E - mail"
                />
                <label
                  htmlFor="email"
                  className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75"
                >
                  Your E - mail
                </label>
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              </div>
            </form>
          </div>

          <div className="bg-white  p-6 max-w-lg w-full">
            <form className="space-y-6" onSubmit={onSubmit}>
              <div className="relative">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-red-600 focus:outline-none focus:ring-0"
                  placeholder="Phone Number"
                />
                <label
                  htmlFor="phone"
                  className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75"
                >
                  Phone Number
                </label>
                {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
              </div>
            </form>
          </div>

          <div className="bg-white p-6 max-w-lg w-full ">
            <form className="space-y-6" onSubmit={onSubmit}>
              <div className="relative">
                <input
                  type="text"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-red-600 focus:outline-none focus:ring-0"
                  placeholder="Your message here"
                />
                <label
                  htmlFor="message"
                  className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-gray-900 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75"
                >
                  Your message here
                </label>
                {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
              </div>
              <div className="pt-10">
                <button
                  type="submit"
                  className="w-32 flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Send to us
                </button>
                {errors.submit && <p className="text-red-500 text-xs mt-4">{errors.submit}</p>}
              </div>
            </form>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  )
}

export default ContactUs;
