import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';

const LoadingScreen = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black flex flex-col items-center justify-center">
        <div className="flex space-x-8">
    
        <ReactLoading type="bubbles" color="red"
                height={100} width={50} />
        </div>
        
      </div>
    );
  }

  return children;
};

export default LoadingScreen;