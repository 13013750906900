import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-red-600 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4">
          {/* Add your logo or brand here if needed */}
        </div>
        <div className="hidden lg:flex items-center space-x-[120px] ml-auto font-semibold">
          <a
            href="/"
            className="hover:text-white transform hover:scale-110 transition duration-300"
          >
            Home
          </a>
          <a
            href="courses"
            className="hover:text-white transform hover:scale-110 transition duration-300"
          >
            Courses
          </a>
          <a
            href="contact"
            className="hover:text-white transform hover:scale-110 transition duration-300"
          >
            Contact Us
          </a>
          <a
            href="about"
            className="hover:text-white transform hover:scale-110 transition duration-300"
          >
            About Us
          </a>
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="pl-8 pr-4 py-2 rounded-full bg-gray-700 focus:outline-none focus:bg-gray-600"
            />
            <FaSearch className="absolute left-2 top-3 text-white" />
          </div>
        </div>
        <button
          className="lg:hidden focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="lg:hidden mt-4">
          <a
            href="/"
            className="block py-2 px-4 hover:text-white transform hover:scale-110 transition duration-300"
          >
            Home
          </a>
          <a
            href="courses"
            className="block py-2 px-4 hover:text-white transform hover:scale-110 transition duration-300"
          >
            Courses
          </a>
          <a
            href="contact"
            className="block py-2 px-4 hover:text-white transform hover:scale-110 transition duration-300"
          >
            Contact Us
          </a>
          <a
            href="about"
            className="block py-2 px-4 hover:text-white transform hover:scale-110 transition duration-300"
          >
            About Us
          </a>
          <div className="py-2 px-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="w-full pl-8 pr-4 py-2 rounded-full bg-gray-700 focus:outline-none focus:bg-gray-600"
              />
              <FaSearch className="absolute left-2 top-3 text-gray-400" />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;