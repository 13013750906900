import React from "react";
import upgrad from '../assets/upgrad.png';
import Facebook from '../assets/facebook.png';
import Instagram from '../assets/instagram.png';
import Youtube from '../assets/youtube.png';

const Footer = () => {
  return (
    <>
      <div className="bg-red-600 min-h-20"></div>
      <footer className="bg-black text-white p-6">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div>
            <h2 className="text-lg font-bold mt-6">upGrad kollam</h2>
            <img src={upgrad} alt="upGrad Logo" className="w-40 h-auto py-5" />
          </div>
          <div className="flex flex-col mt-6">
            <div className="flex items-center mb-6 gap-10">
              <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.354 19.6806C12.6043 17.4243 10.7684 14.6401 9.80312 13.056L9.08312 11.7014C9.33486 11.4319 11.2547 9.38041 12.0866 8.26734C13.1321 6.86965 11.6163 5.60733 11.6163 5.60733C11.6163 5.60733 7.35108 1.35042 6.37903 0.506009C5.40698 -0.339581 4.28803 0.13006 4.28803 0.13006C2.24501 1.44736 0.127064 2.59264 4.28898e-06 8.10065C-0.00473452 13.2575 3.91811 18.5764 8.16023 22.6941C12.4092 27.3444 18.2429 32.0053 23.883 32C29.4019 31.8744 30.5493 29.7612 31.8694 27.7224C31.8694 27.7224 32.3403 26.6067 31.4938 25.6358C30.6468 24.6652 26.3804 20.4079 26.3804 20.4079C26.3804 20.4079 25.1163 18.895 23.7154 19.9392C22.6714 20.718 20.7966 22.4502 20.3449 22.8702C20.3458 22.8717 17.2096 21.2033 15.354 19.6806Z" fill="white" />
              </svg>
              <span>+91 1234567890</span>
            </div>
            <div className="flex items-center mb-6 gap-10">
              <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.354 19.6806C12.6043 17.4243 10.7684 14.6401 9.80312 13.056L9.08312 11.7014C9.33486 11.4319 11.2547 9.38041 12.0866 8.26734C13.1321 6.86965 11.6163 5.60733 11.6163 5.60733C11.6163 5.60733 7.35108 1.35042 6.37903 0.506009C5.40698 -0.339581 4.28803 0.13006 4.28803 0.13006C2.24501 1.44736 0.127064 2.59264 4.28898e-06 8.10065C-0.00473452 13.2575 3.91811 18.5764 8.16023 22.6941C12.4092 27.3444 18.2429 32.0053 23.883 32C29.4019 31.8744 30.5493 29.7612 31.8694 27.7224C31.8694 27.7224 32.3403 26.6067 31.4938 25.6358C30.6468 24.6652 26.3804 20.4079 26.3804 20.4079C26.3804 20.4079 25.1163 18.895 23.7154 19.9392C22.6714 20.718 20.7966 22.4502 20.3449 22.8702C20.3458 22.8717 17.2096 21.2033 15.354 19.6806Z" fill="white" />
              </svg>
              <span>+91 1234567890</span>
            </div>
            <div className="flex items-center mb-6 gap-10">
              <svg width="25" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 18.75C14.9669 18.75 13.9338 18.397 13.0525 17.6816L0 7.10937V21.875C0 23.6003 1.34312 25 3 25H29C30.6569 25 32 23.6009 32 21.875V7.10937L18.95 17.6888C18.0688 18.3984 17.0312 18.75 16 18.75ZM1.01813 5.29297L14.2806 16.0417C15.2925 16.862 16.71 16.862 17.7219 16.0417L30.9844 5.29297C31.5688 4.77214 32 3.97135 32 3.125C32 1.39909 30.6562 0 29 0H3C1.34312 0 0 1.39909 0 3.125C0 3.97135 0.375625 4.77214 1.01813 5.29297Z" fill="white" />
              </svg>
              <span>www.upgrad.com</span>
            </div>
          </div>
          <div className="mt-6">
            <a href="/" className="block mb-6">Home</a>
            <a href="/" className="block mb-6">Courses</a>
            <a href="/" className="block mb-6">Contact</a>
            <a href="/" className="block mb-4">About</a>
          </div>
        </div>
        <div className="flex space-x-10 border-t pl-20">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img src={Facebook} alt="Facebook" className="w-15 h-8 mt-4 " />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src={Instagram} alt="Instagram" className="w-15 h-8 mt-4" />
          </a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <img src={Youtube} alt="YouTube" className="w-15 h-8 mt-4" />
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;